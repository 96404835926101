import { render, staticRenderFns } from "./FilterGroupItem.vue?vue&type=template&id=2a09f120&scoped=true&"
import script from "./FilterGroupItem.vue?vue&type=script&lang=js&"
export * from "./FilterGroupItem.vue?vue&type=script&lang=js&"
import style0 from "./FilterGroupItem.vue?vue&type=style&index=0&id=2a09f120&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a09f120",
  null
  
)

export default component.exports