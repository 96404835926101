<template>
	<div class="grid-section-footer">
		<base-svg class="logo" name="logo" />
		<div class="signal-wrapper">
			<base-font>Designed For</base-font>
			<base-font v-if="this.value" class="logo">{{ logo }}</base-font>
			<base-svg v-else name="teltonika-logo" />
		</div>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
export default {
	components: { BaseFont },
	props: {
		value: String
	},
	computed: {
		logo () {
			const logo = this.value
			return logo
		}
	}
}
</script>

<style lang="scss" scoped>
.grid-section-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 15px;
	margin-top: 40px;
	background: rgba(32, 147, 223, 0.8);
	border-radius: 5px;

	@include media-breakpoint-up(xl) {
		padding: 20px 50px;
	}

	.logo {
		::v-deep(svg) {
			width: 120px;
			height: auto;
			fill: $white;

			@include media-breakpoint-up(xl) {
				width: 210px;
				height: 37px;
			}

			#Group_1586 {
				path {
					fill: $white;
				}
			}
		}
	}

	.signal-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 3px;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}

		.logo{
			font-size: 14px;
			line-height: 14px;
			color: $white;
			letter-spacing: 0.14px;
			font-weight: 800;
			font-size: 14px;
			line-height: 24px;
			text-transform: uppercase;

			@include media-breakpoint-up(xl) {
				font-size: 18px;
				line-height: 18px;
			}
		}

		:deep(p) {
			color: $white;
		}

		p {
			color: $white;
			letter-spacing: 0.14px;
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
		}
	}
}
</style>
