<template>
	<section class="grid-section" v-custom-background="getBackgroundImage">
		<base-container>
			<div class="grid-section-inner">
				<custom-title v-if="value.section_title" :title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`" :header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color" :transform="value.section_title.title_transform"
					:type="value.section_title_type" />
				<subheading :prefix="prefix" />
				<grid-table v-if="value.grid_builder.items.length" :prefix="prefix"
					:grid-area="value.grid_builder.gridArea" :items="value.grid_builder.items"
					:productName="pageProduct?.name" />
				<grid-section-footer :value="value.designed_for" />
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import Subheading from '~/catalog/front/sections/GridSection/components/Subheading'
import GridTable from '~/catalog/front/sections/GridSection/components/GridTable'
import GridSectionFooter from '~/catalog/front/sections/GridSection/components/GridSectionFooter'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
export default {
	props: {
		prefix: String,
		value: Object
	},
	mixins: [productSectionMixin],
	components: {
		GridSectionFooter,
		GridTable,
		Subheading,
		CustomTitle,
		BaseContainer
	},
	computed: {
		getBackgroundImage () {
			const path = this.value.background.path
			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 1425 }
				}
			}
		}
	}
}

</script>

<style lang="scss" scoped>
.grid-section {
	background-color: #e0e2e6;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	@include spacing-section();

	.grid-section-inner {}
}
</style>
