import { render, staticRenderFns } from "./SelectModelNotification.vue?vue&type=template&id=f8f0b1e2&scoped=true&"
import script from "./SelectModelNotification.vue?vue&type=script&lang=js&"
export * from "./SelectModelNotification.vue?vue&type=script&lang=js&"
import style0 from "./SelectModelNotification.vue?vue&type=style&index=0&id=f8f0b1e2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f0b1e2",
  null
  
)

export default component.exports