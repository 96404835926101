import { render, staticRenderFns } from "./OpinionSliderItem.vue?vue&type=template&id=f8d2c032&scoped=true&"
import script from "./OpinionSliderItem.vue?vue&type=script&lang=js&"
export * from "./OpinionSliderItem.vue?vue&type=script&lang=js&"
import style0 from "./OpinionSliderItem.vue?vue&type=style&index=0&id=f8d2c032&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8d2c032",
  null
  
)

export default component.exports